export const environment = {
  version: 'release-backup',
  buildDate: '202412111010',
  environment: 'release-backup',
  release: '001f98f8b8721130acc634615c6fea7031a4e1cc',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://release-backup.api.catch-talents.dev',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
